import { type LoadRefNum, type LocationRefNum } from '@fv/models'

type Props = {
  refNums: LoadRefNum[] | LocationRefNum[]
}
export const RefNumbers = ({ refNums }: Props) => {
  const relevantRefNums = refNums?.filter(r => r.type === 'other')
  if (!relevantRefNums?.length) return null

  return (
    <span className="text-xs">
      Ref#: {relevantRefNums.map(n => n.value).join(' / ')}
    </span>
  )
}
