import { LoadLocation } from '@fv/models'
import { DTO } from '@fv/models/core'

import { MilitaryTime } from './DateTime'

// Hardcoding for now since these will likely change
export const legacyShipTypes = [
  { text: 'Business w/ dock or forklift', value: 'business dock' },
  { text: 'Business w/out dock or forklift', value: 'business no dock' },
  { text: 'Airport', value: 'airport' },
  { text: 'Construction site', value: 'construction' },
  { text: 'Farm', value: 'farm' },
  { text: 'Limited access', value: 'limited access' },
  { text: 'Government/Military', value: 'military' },
  { text: 'Mine', value: 'mine' },
  { text: 'Place of worship', value: 'place of worship' },
  { text: 'Residential', value: 'residential' },
  { text: 'School', value: 'school' },
  { text: 'Tradeshow', value: 'trade show' },
  { text: 'Pier/Port', value: 'pier' },
  { text: 'Prison', value: 'prison' },
] as const

export type LegacyShipType = (typeof legacyShipTypes)[number]['value']
export type QuoteStopType = 'origin' | 'destination'
export type SignatureAccessorialType =
  | 'adult-signature-required'
  | 'indirect-signature-required'
  | 'signature-required'

export interface ShipmentLocation extends DTO<LoadLocation> {
  opensAt?: MilitaryTime
  closesAt?: MilitaryTime
}

export function displayShipType(shipType?: LegacyShipType | '') {
  if (
    !shipType ||
    shipType === 'business dock' ||
    shipType === 'business no dock'
  ) {
    return null
  }

  return legacyShipTypes.find(v => v.value === shipType)?.text ?? null
}
