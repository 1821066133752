import { FvButton, Icon } from '@fv/client-components'
import {
  chargeOptions,
  currencyToString,
  generateCharge,
  getAmount,
  parseCurrency,
} from '@fv/client-core'
import { type ChargeName } from '@fv/models'
import { PriceInput } from '@/components/inputs/PriceInput'
import { SelectInput } from '@/components/inputs/SelectInput'
import { TextField } from '@/components/TextField'

import { type ChargeModel, type Model } from './types'

type Props = {
  onChange: (quote: Model) => void
  model?: Model
}
export const ChargeBreakdownControls = ({ model, onChange }: Props) => {
  const total = getAmount(model)
  const charges = model.charges?.filter(c => c.name !== 'linehaul')

  const handleAddClick = () => {
    onChange({
      ...model,
      charges: [...model.charges, { ...generateCharge() }],
    })
  }

  return (
    <div>
      <div className="-mx-4 mt-3 [&>div]:py-1 px-4 bg-fv-blue-100 p-2 border-t  border-fv-blue-200">
        {charges?.map((c, ix) => (
          <ChargeBreakdownRow
            key={ix}
            charge={c}
            onChange={updatedCharge =>
              onChange({
                ...model,
                charges: model.charges?.map(charge =>
                  charge.id === updatedCharge.id ? updatedCharge : charge,
                ),
              })
            }
            onRemove={() =>
              onChange({
                ...model,
                charges: model.charges?.filter(charge => charge.id !== c.id),
              })
            }
          />
        ))}
      </div>
      <div className="flex items-center gap-x-2 py-3 border-t border-dotted border-fv-blue-300  bg-fv-blue-50 -mx-4 px-4 border-b">
        <FvButton icon="plus" onClick={handleAddClick}>
          Add an additional charge
        </FvButton>
        <div className="ml-auto">
          <span className="text-right">Total price = </span>
          <span className="inline-block p-2 bg-fv-beer-50 border border-[#dedbc0] ml-2">
            ${currencyToString(total)}
          </span>
        </div>
      </div>
    </div>
  )
}

type RowProps = {
  charge: ChargeModel
  onRemove: () => void
  onChange: (value: ChargeModel) => void
}
const ChargeBreakdownRow = ({ charge, onRemove, onChange }: RowProps) => {
  return (
    <div className="flex items-center gap-x-2">
      <SelectInput
        className="w-auto flex-shrink-0"
        name={`charge-type-${charge.id}`}
        onChange={e => {
          onChange({ ...charge, name: e.target.value as ChargeName })
        }}
        options={chargeOptions}
        value={charge.name}
        required
      />

      {charge.name === 'other' && (
        <TextField
          value={charge.providerDetail || ''}
          name={`charge-desc-${charge.id}`}
          placeholder="Enter charge description"
          onChange={e =>
            onChange({ ...charge, providerDetail: e.target.value })
          }
        />
      )}
      <div className="h-0 flex-1 border-t border-dotted border-fv-blue-300" />

      <FvButton
        icon="trash"
        iconClass="text-fv-orange"
        className="mr-2"
        onClick={onRemove}
      />
      <div className="input-group input-group--flex b400:basis-full mb-0 ml-auto">
        <div className="input-group-addon">
          <Icon icon="dollar-sign" className="color-dark" />
        </div>
        <PriceInput
          name={`charge-amount-${charge.id}`}
          className="form-control b400:w-full w-[5rem] text-right"
          disabled={false}
          value={charge.amount ? currencyToString(charge.amount) : ''}
          onChange={e => {
            const amount = parseCurrency(e.target.value)
            onChange({ ...charge, amount: isNaN(amount) ? 0 : amount })
          }}
          required={true}
        />
      </div>
    </div>
  )
}
