import pluralize from 'pluralize'
import { useRef } from 'react'
import toast from 'react-hot-toast'

import {
  FvButton,
  Icon,
  TextAreaField,
  ValidatedForm,
} from '@fv/client-components'
import { modelToDto } from '@fv/client-core'
import { supportMessage } from '@/constants'
import { type Opportunity } from '@/types/Opportunity'
import { getBidExpiration } from '@/utils/getBidExpiration'

import { useSendMessage } from '../messaging/queries'
import { useQuotes } from '../opp-card/hooks/useQuotes'
import { useRetractQuote } from '../opp-card/hooks/useRetractQuote'
import { useSubmitQuotes } from '../opp-card/hooks/useSubmitQuotes'
import { QuoteFormEntry } from './QuoteFormEntry'
import {
  useQuotesFormActions,
  useQuotesFormHelpers,
  useQuotesFormState,
} from './QuotesFormProvider'
import { QuotingDescription } from './QuotingDescription'
import { useCanEditQuotes } from './utils'

type Props = {
  opportunity: Opportunity
  hasQuoted?: boolean
}
export const QuotesForm = ({ opportunity, hasQuoted }: Props) => {
  const formRef = useRef<HTMLFormElement>(null)
  const term = opportunity.status === 'confirmed' ? 'quote' : 'bid'
  const { models, message, selected } = useQuotesFormState()
  const { switchToCharges } = useQuotesFormHelpers()
  const { setMessage, setContractedRateLock, updateModel } =
    useQuotesFormActions()
  const quotesQuery = useQuotes(opportunity.loadId)
  const originalQuotes = quotesQuery.data
  const { isExpired } = getBidExpiration(opportunity)
  const canEdit = useCanEditQuotes(opportunity, originalQuotes)
  const { sendMessage, isSendingMessage } = useSendMessage(opportunity.loadId)
  const retractQuote = useRetractQuote(opportunity.quoteRequestId)
  const submitQuotes = useSubmitQuotes(
    opportunity.status,
    opportunity.quoteRequestId,
  )
  const isBusy =
    retractQuote.isLoading || submitQuotes.isLoading || isSendingMessage
  const hasChargeBreakdown = models[0]?.charges?.length > 1

  if (hasQuoted && quotesQuery.isError && !models.length) {
    return (
      <div className="box box--help-gradient mt-4">
        <div className="icon-message">
          <Icon className="color-secondary" icon="exclamation-triangle" />
          <p className="mb-0">
            Unable to load {term}, {supportMessage}.
          </p>
        </div>
      </div>
    )
  }

  const handleSubmit = async () => {
    if (!hasQuoted) {
      try {
        await submitQuotes.mutateAsync({
          loadId: opportunity.loadId,
          messageText: message,
          quotes: models.map(({ quoteId, ...model }) => modelToDto(model)),
        })
        setMessage('')
      } catch {
        toast.error(`Unable to add ${term}, ${supportMessage}`)
      }
      formRef.current?.reset()
      return
    }
    if (!canEdit) {
      if (!message) return
      return sendMessage({
        messageText: message,
        onSuccess: () => {
          setMessage('')
        },
      })
    }

    const quoteToRetract = models.find(m => !selected.includes(m.quoteId))
    if (quoteToRetract) {
      try {
        await retractQuote.mutateAsync({
          loadId: opportunity.loadId,
          quoteId: quoteToRetract.quoteId,
        })
      } catch {
        toast.error(`Unable to retract ${term}, ${supportMessage}`)
      }
    }

    const quoteDtos = selected.map(id => models.find(m => m.quoteId === id))
    const quotesToUpdate = quoteDtos.filter(dto => {
      const original = originalQuotes.find(q => q._id === dto.quoteId)

      return (
        dto.amount !== original?.amount ||
        dto.charges?.some(
          charge =>
            !original?.charges?.some(
              og => og.name === charge.name && og.amount === charge.amount,
            ),
        ) ||
        original?.charges?.some(
          charge =>
            !dto.charges?.some(
              d => d.amount === charge.amount && d.name === charge.name,
            ),
        ) ||
        dto.equipmentType !== original?.equipmentType ||
        dto.quoteNum !== original?.quoteNum
      )
    })

    if (!quotesToUpdate.length && !quoteToRetract) {
      return toast.error(`Must make a change to update ${pluralize(term, 2)}.`)
    }

    const originalApiQuote = originalQuotes.find(q => q.method === 'api')
    const updatedApiQuote = quotesToUpdate.find(
      q => q.quoteId === originalApiQuote?._id,
    )
    if (originalApiQuote && updatedApiQuote) {
      if (updatedApiQuote.quoteNum === (originalApiQuote.quoteNum ?? '')) {
        updateModel({
          ...updatedApiQuote,
          errors: {
            ...updatedApiQuote.errors,
            quoteNum: 'Quote number must be changed.',
          },
        })
        formRef.current?.reportValidity()
      }
      if (updatedApiQuote.amount === originalApiQuote.amount) {
        updateModel({
          ...updatedApiQuote,
          errors: {
            ...updatedApiQuote.errors,
            amount: 'Amount must be changed.',
          },
        })
        formRef.current?.reportValidity()
      }
    }

    if (quotesToUpdate.length) {
      try {
        await submitQuotes.mutateAsync({
          isUpdate: true,
          loadId: opportunity.loadId,
          messageText: message,
          quotes: quotesToUpdate.map(modelToDto),
        })
        setContractedRateLock(true)
        setMessage('')
      } catch {
        toast.error(
          `Unable to update ${pluralize('quote', quotesToUpdate.length)}, ${supportMessage}`,
        )
      }
      formRef.current?.reset()
    }
  }

  return (
    <ValidatedForm
      onValidSubmit={handleSubmit}
      className="form-currently-quoting"
      ref={formRef}
    >
      <div>
        <QuotingDescription opportunity={opportunity} />

        <div className="mb-3">
          {hasQuoted &&
            models.map(model => (
              <QuoteFormEntry
                value={model}
                key={model.quoteId}
                disabled={isExpired || !canEdit}
                quote={originalQuotes.find(q => q._id === model.quoteId)}
              />
            ))}

          {!hasQuoted && <QuoteFormEntry value={models[0]} />}
        </div>
        <div className="form-group">
          <TextAreaField
            className="form-control"
            disabled={isBusy || isExpired}
            value={message}
            onChange={e => setMessage(e.target.value)}
            maxLength={500}
            name="message"
            placeholder="Include an optional message here."
          />
        </div>
        <div className="flex items-center justify-between">
          <div>
            {!hasChargeBreakdown && (canEdit || !hasQuoted) && (
              <FvButton
                icon="money-check-edit-alt"
                onClick={() => switchToCharges(models[0])}
              >
                Add charges
              </FvButton>
            )}
          </div>
          <FvButton
            disabled={isBusy || isExpired}
            type="submit"
            theme="default"
            loading={isBusy}
            className="self-end"
            icon={'paper-plane'}
          >
            <span>
              {!hasQuoted && 'Send bid'}
              {hasQuoted &&
                (canEdit
                  ? `Update ${pluralize(term, originalQuotes.length)}`
                  : 'Send message')}
            </span>
          </FvButton>
        </div>
      </div>
    </ValidatedForm>
  )
}
