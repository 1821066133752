import sum from 'lodash/sum'
import {
  createContext,
  type PropsWithChildren,
  useContext,
  useEffect,
  useRef,
} from 'react'
import { createStore, useStore } from 'zustand'

import { generateCharge, generateString } from '@fv/client-core'
import { toggleArrayItem } from '@fv/models/core'
import { Loading } from '@/components/shared/Loading'
import { useAppSettings } from '@/hooks/settings'
import { type Opportunity } from '@/types/Opportunity'

import { useQuotes } from '../opp-card/hooks/useQuotes'
import { type Model, type QuotesFormState, type QuotesFormStore } from './types'
import { createQuoteEditModel, useEquimentTypeOptions } from './utils'

const createQuotesFormStore = (state?: QuotesFormState) => {
  const defaultStore: QuotesFormState = {
    ...state,
  }
  return createStore<QuotesFormStore>()(set => ({
    ...defaultStore,
    actions: {
      setMessage: message => set({ message }),
      resetModels: models => set({ models }),
      toggleSelected: (id, toggle) =>
        set(p => ({ selected: toggleArrayItem(p.selected, id, toggle) })),
      toggleContractedRateLock: () =>
        set(p => ({ contractedRatesLocked: !p.contractedRatesLocked })),
      setContractedRateLock: contractedRatesLocked =>
        set({ contractedRatesLocked }),
      updateModel: updatedModel =>
        set(prev => ({
          ...prev,
          models: prev.models.map(m =>
            m.quoteId === updatedModel.quoteId ? updatedModel : m,
          ),
        })),
    },
  }))
}

type QuotesFormStoreEntity = ReturnType<typeof createQuotesFormStore>
const QuotesFormContext = createContext<QuotesFormStoreEntity | null>(null)

type Props = PropsWithChildren<{
  opportunity: Opportunity
}>
export const QuotesFormProvider = ({ children, opportunity }: Props) => {
  const equipmentOptions = useEquimentTypeOptions(opportunity)
  const quotesQuery = useQuotes(opportunity.loadId)
  const settingsQuery = useAppSettings()
  const store = useRef(
    createQuotesFormStore({
      opportunity,
      message: '',
      models: [
        {
          amount: 0,
          currency: 'usd',
          equipmentType: opportunity.equipmentRequested.type,
          quoteNum: '',
          quoteId: generateString(12),
        },
      ],
      selected: [],
      equipmentOptions,
      contractedRatesLocked: true,
    }),
  ).current
  useEffect(() => {
    if (quotesQuery.data && quotesQuery.data.length) {
      store.setState({
        models: quotesQuery.data.map(createQuoteEditModel),
        selected: quotesQuery.data.map(q => q._id),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotesQuery.data])

  if (quotesQuery.isLoading || settingsQuery.isLoading) {
    return <Loading />
  }

  return (
    <QuotesFormContext.Provider value={store}>
      {children}
    </QuotesFormContext.Provider>
  )
}

export function useQuotesFormContext<T>(
  selector: (state: QuotesFormStore) => T,
): T {
  const store = useContext(QuotesFormContext)
  if (!store) throw new Error('Missing QuotesFormProvider in the tree')
  return useStore(store, selector)
}

export const useQuotesFormState = () => {
  return useQuotesFormContext(({ actions, ...state }) => state)
}

export const useQuotesFormActions = () => {
  return useQuotesFormContext(({ actions }) => actions)
}

export const useQuotesFormHelpers = () => {
  const { updateModel } = useQuotesFormActions()
  const switchToCharges = (model: Model) =>
    updateModel({
      ...model,
      charges: [
        generateCharge({ name: 'linehaul', amount: model.amount }),
        { ...generateCharge() },
      ],
    })
  const switchFromCharges = (model: Model) =>
    updateModel({
      ...model,
      amount: sum(model.charges?.map(c => c.amount)),
      charges: undefined,
    })

  return {
    switchToCharges,
    switchFromCharges,
  }
}
