import pluralize from 'pluralize'

import { HazardDetails, Icon } from '@fv/client-components'
import {
  formatNumber,
  getDistanceDisplay,
  getEquipmentLabel,
} from '@fv/client-core'
import { type LoadEquipment, type LoadHandlingUnit } from '@fv/client-types'
import { type Locale } from '@fv/models'
import { Accessorials } from '@/components/shared/EquipmentAccessorial'
import { useLocale } from '@/hooks/useLocale'
import { useTypeNames } from '@/hooks/useTypeNames'

import { type Opportunity } from '../../types/Opportunity'

type LoadDetailsProps = {
  load: Opportunity
}

export const LoadDetails = ({ load }: LoadDetailsProps) => {
  const { documents, equipmentRequested, items, quotedEquipmentType, status } =
    load
  const { equipmentName } = useTypeNames()
  const locale = useLocale()
  const isAwarded = status === 'awarded' || status === 'confirmed'
  const equipmentLabel = getEquipmentLabel({
    altEquipmentNames: equipmentRequested.alternateTypes?.map(equipmentName),
    equipmentName: equipmentName(
      quotedEquipmentType ?? equipmentRequested.type,
    ),
    isAwarded: isAwarded || !!quotedEquipmentType,
  })
  const otherDocs =
    documents?.filter(d => d.type === 'other' && d.source === 'shipper') ?? []

  const showShipperDocs =
    status !== 'confirmed' && status !== 'lost' && otherDocs.length > 0

  return (
    <>
      <li className="general-list__item items-start">
        <Icon icon="truck" className="fa-fw color-dark" transform="down-2.5" />
        <p className="ml-2 pb-0">
          {!!load.distance && `${getDistanceDisplay(load, locale)} / `}
          Max weight of {formatNumber(
            equipmentRequested.weight,
            locale,
          )} total {equipmentRequested.weightUOM}.
          {equipmentRequested.declaredValue &&
            ` valued at ${composeDeclaredValue(
              equipmentRequested,
              locale,
            )}`}{' '}
          via {equipmentLabel}
          <Accessorials
            accessorials={equipmentRequested.accessorials}
            isHazardous={equipmentRequested.isHazardous}
          />
        </p>
      </li>

      {items?.map((item, index) => (
        <li className="general-list__item flex items-start" key={index}>
          <Icon
            icon="dot-circle"
            className="fa-fw color-dark"
            transform="down-3"
          />

          <p className="mb-0 ml-2">
            {getItemDetails({
              ...item,
              weight: formatNumber(item?.weight, locale),
            })}
            {Boolean(item?.hazardous?.length) && (
              <>
                {' / '}
                <Icon icon="radiation-alt" className="fa-fw color-secondary" />
                <span className="ml-1">
                  Hazardous
                  <HazardDetails
                    hazardDetails={item?.hazardous}
                    description={item?.description}
                  />
                </span>
              </>
            )}
          </p>
        </li>
      ))}
      {showShipperDocs && (
        <li className="general-list__item items-start whitespace-pre-wrap">
          <Icon
            icon="file-alt"
            className="fa-fw color-dark"
            transform="down-2.5"
          />
          <span className="mr-2">Shipper files attached:</span>
          {otherDocs?.map((document, index) => [
            index > 0 && ' / ',
            <a
              className="standard-link--secondary"
              href={document.url}
              key={document._id}
              rel="noreferrer"
              target="_blank"
            >
              File {index + 1}
            </a>,
          ])}
        </li>
      )}
    </>
  )
}

function composeDeclaredValue(equipment: LoadEquipment, locale: Locale) {
  const { declaredValue, declaredValueCurrency } = equipment

  if (!declaredValue) return ''

  return `$${formatNumber(
    declaredValue,
    locale,
  )} ${declaredValueCurrency.toUpperCase()}`
}

function getItemDetails(
  item: Omit<LoadHandlingUnit, 'weight'> & { weight: string | null },
) {
  const {
    freightClass,
    height,
    length,
    nmfcNumber,
    nmfcSubNumber,
    quantity,
    type,
    weight,
    weightUOM,
    width,
    stackable,
  } = item ?? {}

  let nmfc = nmfcNumber ? `${nmfcNumber}` : null
  if (nmfc && nmfcSubNumber) nmfc += `.${nmfcSubNumber}`

  let details = pluralize(type ?? '', quantity, true)

  if (length) details += ` / ${length} x ${width} x ${height}`
  if (weight) details += ` / ${weight} ${weightUOM}`
  if (freightClass) details += ` / Class ${freightClass}`
  if (nmfc) details += ` / NMFC ${nmfc}`
  if (stackable) details += ` / Stackable`

  return details
}
